import { enumSelector } from '@/shared/enumSelector';
import { PaymentBatchType, PaymentCategory, PaymentType, SendVia, WireBank, PaymentChannel } from '@/shared/schema/globalTypes';
import UICheckBox from '@/shared/ui/forms/checkbox/checkbox.vue';
import UIDatePicker from '@/shared/ui/forms/date-picker/date-picker.vue';
import UIInput from '@/shared/ui/forms/input/ui-input.vue';
import NumberInput from '@/shared/ui/forms/number-input.vue';
import UITextarea from '@/shared/ui/forms/textarea/ui-textarea.vue';
import S3Uploader from '@/shared/ui/s3uploader';
import BatchLookup from '../batch-lookup';
import { CCSelector } from '../cc-selector';
function batchTypesFromPaymentType(type) {
    if (type === PaymentType.Check)
        return [PaymentBatchType.Check];
    if (type === PaymentType.Cash)
        return [PaymentBatchType.Check];
    if (type === PaymentType.CreditCard)
        return [PaymentBatchType.CreditCard];
    return null;
}
export const formDescription = {
    donorSelector: [
        {
            inlineLabel: false,
            rows: [
                {
                    type: 'single',
                    component: 'donorSelector',
                    field: 'selectedDonor'
                }
            ]
        }
    ],
    info: [
        {
            inlineLabel: true,
            label: 'Type',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'type',
                    component: [
                        enumSelector({ ...PaymentType }),
                        {
                            attrs: {
                                'data-testid': 'type'
                            }
                        }
                    ]
                }
            ]
        },
        {
            inlineLabel: true,
            label: 'Wire Bank',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'wireBank',
                    component: enumSelector(WireBank)
                }
            ],
            condition: form => form.type === PaymentType.Wire
        },
        {
            condition: values => values.type !== 'ChargeCard',
            inlineLabel: true,
            label: 'Payment Date',
            rows: [
                {
                    type: 'single',
                    component: UIDatePicker,
                    field: 'date'
                }
            ]
        },
        {
            condition: values => values.type === 'Check',
            inlineLabel: true,
            label: 'Received Date',
            rows: [
                {
                    type: 'single',
                    component: UIDatePicker,
                    field: 'receivedDate'
                }
            ]
        },
        {
            inlineLabel: true,
            label: 'Amount',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'amount',
                    proportion: 4,
                    component: 'amount-value'
                }
            ]
        },
        {
            inlineLabel: true,
            label: 'Category',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'category',
                    component: enumSelector(PaymentCategory)
                }
            ]
        },
        {
            condition: values => values.type !== 'ChargeCard',
            inlineLabel: true,
            label: 'Batch',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'batchId',
                    component: [
                        BatchLookup,
                        values => ({
                            props: {
                                types: batchTypesFromPaymentType(values.type)
                            }
                        })
                    ]
                }
            ]
        }
    ],
    receipt: [
        {
            inlineLabel: true,
            label: 'Send me a receipt',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'sendReceipt',
                    component: UICheckBox
                }
            ]
        },
        {
            inlineLabel: true,
            label: 'Send via',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'sendReceiptVia',
                    component: enumSelector(SendVia)
                }
            ],
            condition: form => form.sendReceipt
        }
    ],
    details: [
        {
            inlineLabel: true,
            label: 'Notes',
            rows: [
                {
                    type: 'single',
                    field: 'notes',
                    component: UITextarea
                }
            ]
        },
        // Checks
        {
            inlineLabel: true,
            label: 'MICR',
            condition: form => form.type === PaymentType.Check,
            rows: [
                {
                    type: 'single',
                    field: 'MICR',
                    component: UIInput
                }
            ]
        },
        {
            inlineLabel: true,
            label: 'Back Scan',
            condition: form => form.type === PaymentType.Check,
            rows: [
                {
                    type: 'single',
                    field: 'backScan',
                    component: [
                        S3Uploader,
                        values => ({
                            props: {
                                location: `checks/${values.id}`
                            }
                        })
                    ]
                }
            ]
        },
        {
            inlineLabel: true,
            label: 'Front Scan',
            rows: [
                {
                    type: 'single',
                    field: 'frontScan',
                    component: [
                        S3Uploader,
                        values => ({
                            props: {
                                location: `checks/${values.id}`
                            }
                        })
                    ]
                }
            ],
            condition: form => form.type === PaymentType.Check
        },
        {
            inlineLabel: true,
            label: 'Check Number',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'checkNum',
                    component: UIInput
                }
            ],
            condition: form => form.type === PaymentType.Check
        },
        {
            inlineLabel: true,
            label: 'Deposit Date',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'depositDate',
                    component: UIDatePicker
                }
            ],
            condition: form => form.type === PaymentType.Check
        },
        // CC
        {
            inlineLabel: true,
            label: 'Transaction ID',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'transactionId',
                    component: UIInput
                }
            ],
            condition: form => form.type === PaymentType.CreditCard ||
                form.type === PaymentType.ACH ||
                form.type === PaymentType.Bit ||
                form.type === PaymentType.GooglePay ||
                form.type === PaymentType.Paypal ||
                form.type === PaymentType.Venmo ||
                form.type === PaymentType.ApplePay
        },
        // Inkind
        {
            inlineLabel: true,
            label: 'Payment Description',
            rows: [
                {
                    type: 'single',
                    field: 'description',
                    component: UIInput
                }
            ],
            condition: form => form.type === PaymentType.InKind
        },
        // Stock
        {
            inlineLabel: true,
            label: 'Date of sold',
            rows: [
                {
                    type: 'single',
                    field: 'dateOfSold',
                    component: UIDatePicker
                }
            ],
            condition: form => form.type === PaymentType.Stock
        },
        {
            inlineLabel: true,
            label: 'Stock Symbol',
            rows: [
                {
                    type: 'single',
                    field: 'stockSymbol',
                    component: UIInput
                }
            ],
            condition: form => form.type === PaymentType.Stock
        },
        {
            inlineLabel: true,
            label: 'How It Sold For',
            rows: [
                {
                    type: 'single',
                    field: 'howItSoldFor',
                    component: UIInput
                }
            ],
            condition: form => form.type === PaymentType.Stock
        },
        {
            inlineLabel: true,
            label: 'High/Low',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'dayHigh',
                    component: UIInput
                },
                {
                    type: 'single',
                    field: 'dayLow',
                    component: UIInput
                }
            ],
            condition: form => form.type === PaymentType.Stock
        },
        {
            inlineLabel: true,
            label: '# of Shares',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'numOfShares',
                    component: UIInput
                }
            ],
            condition: form => form.type === PaymentType.Stock
        },
        // Crypto
        {
            inlineLabel: true,
            label: 'Bank',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'bank',
                    component: enumSelector(WireBank)
                }
            ],
            condition: form => form.type === PaymentType.Crypto
        },
        {
            inlineLabel: true,
            label: 'Crypto Type',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'cryptoType',
                    component: UIInput
                }
            ],
            condition: form => form.type === PaymentType.Crypto
        },
        {
            inlineLabel: true,
            label: 'Exchange Rate',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'exchangeRate',
                    component: NumberInput
                }
            ],
            condition: form => form.type === PaymentType.Crypto
        },
        {
            inlineLabel: true,
            label: 'Channel',
            class: 'smaller',
            rows: [
                {
                    type: 'single',
                    field: 'channel',
                    component: enumSelector(PaymentChannel)
                }
            ]
        }
    ],
    applications: [
        {
            inlineLabel: false,
            class: 'bigger',
            rows: [
                {
                    type: 'single',
                    field: 'applications',
                    component: 'applications'
                }
            ]
        }
    ],
    cardInfo: [
        {
            inlineLabel: false,
            rows: [
                {
                    field: 'card',
                    type: 'single',
                    component: [
                        CCSelector,
                        state => ({
                            props: {
                                testMode: JSON.parse(process.env.VUE_APP_PAYMENT_TEST_MODE),
                                donor: state.selectedDonor,
                                amount: state.amount.amount,
                                currency: state.amount.currency
                            }
                        })
                    ],
                    condition: state => state.type == PaymentType.ChargeCard
                }
            ]
        }
    ],
    dafInfo: [
        {
            inlineLabel: true,
            label: 'DAF Name',
            rows: [
                {
                    type: 'single',
                    field: 'dafName',
                    component: UIInput
                }
            ]
        }
    ],
    iraInfo: [
        {
            inlineLabel: true,
            label: 'IRA Name',
            rows: [
                {
                    type: 'single',
                    field: 'iraName',
                    component: UIInput
                }
            ]
        }
    ],
    matchingGiftInfo: [
        {
            inlineLabel: true,
            label: 'MatchingGift Name',
            rows: [
                {
                    type: 'single',
                    field: 'matchingGiftName',
                    component: UIInput
                }
            ]
        }
    ]
};
