<template>
	<input
		:multiple="multiple ? 'multiple' : null"
		:accept="accept ? accept : null"
		type="file"
		v-bind="props"
		v-on="listeners"
		:class="classes"
		ref="fileInput"
	/>
</template>
<script>
async function maybeCompress(file) {
	return new Promise(res => {
		if (file.type.match('image.*')) {
			const canvas = document.createElement('canvas')
			const context = canvas.getContext('2d')
			const img = new Image()

			var reader = new FileReader()

			reader.readAsDataURL(file)
			reader.onload = async evt => {
				if (evt.target.readyState == FileReader.DONE) {
					document.querySelector('body').append(img)

					img.onload = () => {
						canvas.width = img.width
						canvas.height = img.height
						context.drawImage(img, 0, 0, img.width, img.height)

						canvas.toBlob(
							blob => {
								blob.lastModifiedDate = file.lastModifiedDate
								const parts = file.name.split('.')
								if (parts.length > 1) {
									parts[parts.length - 1] = 'webp'
								} else {
									parts.push('webp')
								}
								blob.name = parts.join('.')

								res(blob)
							},
							'image/webp',
							0.3
						)
					}

					img.src = evt.target.result
				}
			}
		} else {
			res(file)
		}
	})
}
export default {
	name: 'Upload',
	props: ['size', 'multiple', 'accept'],
	data() {
		return {
			listeners: {
				...this.$listeners,
				input: async e => {
					const files = await Promise.all(
						[...e.target.files].map(file => maybeCompress(file, this.$refs.img))
					)

					const validFiles = this.validateFiles(files)
					if (!validFiles.length) return

					this.$emit('input', validFiles)
				}
			}
		}
	},
	computed: {
		props() {
			delete this.$attrs.value

			return {
				...this.$attrs,
				...this.$props
			}
		},
		classes() {
			const classes = {}

			if (this.size) {
				classes[`size-${this.size}`] = true
			}

			return classes
		}
	},
	methods: {
		validateFiles(files) {
			const allowedTypes = Array.isArray(this.accept)
				? this.accept
				: this.accept
				? this.accept.split(',').map(type => type.trim())
				: []

			if (allowedTypes.length === 0) {
				return files
			}

			return files.filter(file => {
				return allowedTypes.some(type => {
					if (type.startsWith('.')) {
						// If accept is an extension like ".jpg"
						return file.name.toLowerCase().endsWith(type.toLowerCase())
					} else {
						// If accept is a MIME type like "image/png"
						return file.type === type
					}
				})
			})
		}
	}
}
</script>

<style lang="scss" scoped>
input {
	box-sizing: border-box;
	height: 36px;
	width: 100%;
	display: block;
	border: none;
	padding: 0;
	border-radius: 0;
	background: none;
	font-size: var(--main-font-size);
	color: #666666;
}
</style>
