import { __decorate, __metadata } from "tslib";
import ProtectedBlock from '@/shared/authentication/ProtectedBlock';
import GetSignedUrl from '@/shared/components/get-signed-url/get-signed-url.vue';
import Icon from '@/shared/components/icon/icon.vue';
import Modal from '@/shared/components/modal/modal.vue';
import SmallPaymentAuditLog from '@/shared/components/pledge-form/payment/small-audit-view';
import Popover from '@/shared/components/popover/popover.vue';
import { FormBuilder } from '@/shared/form-builder';
import Box from '@/shared/layout/box/layout-box.vue';
import layoutText from '@/shared/layout/text/layout-text.vue';
import { notify } from '@/shared/notifications';
import { CurrencyType, PaymentCategory, PaymentStatus, PaymentType, SendVia, UserRole, WireBank, PaymentChannel } from '@/shared/schema/globalTypes';
import UIButton from '@/shared/ui/button/ui-button.vue';
import Card from '@/shared/ui/card/card.vue';
import AmountWithCurrency from '@/shared/ui/forms/amount-with-currency';
import UICheckBox from '@/shared/ui/forms/checkbox/checkbox.vue';
import UIDatePicker from '@/shared/ui/forms/date-picker/date-picker.vue';
import layoutFormRow from '@/shared/ui/forms/form-row/layout-form-row.vue';
import FormSection from '@/shared/ui/forms/form-section/form-section.vue';
import UIInput from '@/shared/ui/forms/input/ui-input.vue';
import UISelect from '@/shared/ui/forms/select/ui-select.vue';
import UILabel from '@/shared/ui/forms/label/ui-label.vue';
import UISwitch from '@/shared/ui/forms/switch/switch.vue';
import S3Uploader from '@/shared/ui/s3uploader';
import * as TableGroup from '@/shared/ui/table';
import { formatDate, formatMoney, addWithRounding, monthDayYearToDate } from '@/shared/util';
import { startCase } from 'lodash';
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { enumSelector } from '../../enumSelector';
import BatchLookup from '../batch-lookup';
import VoidModal from './void-modal.vue';
import { dateScalarToDate } from '@velocity/shared/src/utils';
import DonorLookup from '@/shared/components/payment-form/donor-lookup';
import ReceiptPreview from '@/pages/household/pages/giving/receipt-preview';
import NumberInput from '@/shared/ui/forms/number-input.vue';
let EditPayment = class EditPayment extends Vue {
    router;
    updatePayment;
    updatePaymentType;
    voidPayment;
    unvoidPayment;
    deletePayment;
    movePayment;
    processing;
    payment;
    paymentId;
    pledgeCurrency;
    showAudit;
    access_token;
    PaymentCategory = PaymentCategory;
    UserRole = UserRole;
    formatDate = formatDate;
    formatMoney = formatMoney;
    startCase = startCase;
    monthDayYearToDate = monthDayYearToDate;
    dateScalarToDate = dateScalarToDate;
    moveModal = false;
    delModal = false;
    receiptModal = false;
    withPledges = false;
    selectedDonor = null;
    get originalAmount() {
        return this.payment ? { amount: this.payment?.originalAmount, currency: this.payment.currency } : null;
    }
    get originalDate() {
        return this.payment ? monthDayYearToDate(this.payment.date) : null;
    }
    isVoidModalVisible = false;
    // Payment form is saved here, because when void modal confirmation is closed,
    // the payment data is needed to finish up the payment edit
    paymentForm = null;
    applicationAmount(app) {
        let res = formatMoney(app.originalAmount, this.payment?.currency || CurrencyType.USD);
        if (this.payment?.currency !== CurrencyType.USD)
            res += ` (${formatMoney(app.amount)})`;
        return res;
    }
    updateApplicationValue(app, value, paymentForm) {
        if (!value.amount)
            return;
        const application = paymentForm.applications.find(currentApp => currentApp.pledgeId === app.pledgeId);
        if (!application)
            return;
        application.originalAmount = value.amount;
        application.currency = value.currency;
    }
    getReferrerHost() {
        if (!this.payment?.referrer)
            return '';
        let referrer = this.payment.referrer;
        if (referrer.indexOf('http') !== 0)
            referrer = 'http://' + referrer;
        const url = new URL(referrer);
        return url.hostname + url.pathname;
    }
    closeModal() {
        this.moveModal = false;
        this.delModal = false;
        this.withPledges = false;
        this.receiptModal = false;
        this.selectedDonor = null;
    }
    async onDeletePayment() {
        if (!this.payment || !this.payment.id)
            return;
        await this.deletePayment(this.payment.id, this.withPledges);
        this.closeModal();
        notify('Payment was deleted successfully');
        if (this.withPledges) {
            this.router.history.push(`/household/${this.payment.donorId}/giving-history`);
        }
        else {
            setTimeout(() => {
                this.$emit('deleted');
            }, 1000);
        }
    }
    get receiptPreviewProps() {
        return {
            donorId: this.payment?.donorId,
            paymentId: this.payment?.id,
            pledgeId: this.payment?.applications?.length && this.payment.applications[0].pledgeId
        };
    }
    async onMovePayment() {
        if (!this.payment || !this.payment.id || !this.selectedDonor)
            return;
        await this.movePayment(this.payment.id, this.selectedDonor.id, this.withPledges);
        notify('Payment was moved successfully');
        if (this.withPledges) {
            this.router.history.push(`/household/${this.payment.donorId}/giving-history`);
        }
        this.closeModal();
        setTimeout(() => {
            this.$emit('deleted');
        }, 1000);
    }
    get receiptLink() {
        if (!this.payment || this.payment.applications.length !== 1)
            return null;
        return process.env.VUE_APP_API.replace('/graphql', `/pdf/pledgeReceipt/${this.payment.applications[0].pledgeId}/${this.payment.id}?token=${this.access_token}`);
    }
    formDescription = {
        info: [
            {
                inlineLabel: true,
                label: 'Donor',
                rows: [
                    {
                        type: 'single',
                        component: [UIInput, () => ({ props: { disabled: true } })],
                        field: 'donorName'
                    }
                ]
            },
            {
                inlineLabel: true,
                label: 'Payment Date',
                rows: [
                    {
                        type: 'single',
                        component: UIDatePicker,
                        field: 'date'
                    }
                ]
            },
            {
                condition: values => values.type === PaymentType.Check,
                inlineLabel: true,
                label: 'Received Date',
                rows: [
                    {
                        type: 'single',
                        component: UIDatePicker,
                        field: 'receivedDate'
                    }
                ]
            },
            {
                inlineLabel: true,
                label: 'Category',
                rows: [
                    {
                        type: 'single',
                        field: 'category',
                        component: enumSelector(PaymentCategory)
                    }
                ]
            },
            {
                inlineLabel: true,
                label: 'Type',
                rows: [
                    {
                        type: 'single',
                        field: 'type',
                        component: enumSelector(PaymentType, undefined, [PaymentType.ChargeCard])
                    }
                ],
                condition: form => form.type !== PaymentType.ChargeCard
            },
            {
                inlineLabel: true,
                label: 'Wire Bank',
                class: 'smaller',
                rows: [
                    {
                        type: 'single',
                        field: 'wireBank',
                        component: enumSelector(WireBank)
                    }
                ],
                condition: form => form.type === PaymentType.Wire
            },
            {
                inlineLabel: true,
                label: 'Type',
                rows: [
                    {
                        type: 'single',
                        field: 'type',
                        component: [UIInput, () => ({ props: { disabled: true } })]
                    }
                ],
                condition: form => form.type === PaymentType.ChargeCard
            },
            {
                inlineLabel: true,
                label: 'Amount',
                class: 'smaller',
                rows: [
                    {
                        type: 'single',
                        field: 'amount',
                        proportion: 4,
                        component: 'amount-value'
                    }
                ]
            },
            {
                inlineLabel: true,
                label: 'Batch',
                class: 'smaller',
                rows: [
                    {
                        type: 'single',
                        field: 'batch',
                        component: [
                            BatchLookup,
                            _ => ({
                                props: {
                                    withFilter: true,
                                    type: this.payment && this.payment.type,
                                    value: this.payment && this.payment.batch
                                }
                            })
                        ]
                    }
                ]
            },
            {
                inlineLabel: true,
                label: 'Batch Date',
                class: 'smaller',
                rows: [
                    {
                        type: 'single',
                        field: 'batchDate',
                        component: 'batch-date'
                    }
                ],
                condition: form => !!form.batchDate && !this.paymentBatchChanged(form)
            },
            {
                inlineLabel: true,
                label: 'Voided',
                rows: [
                    {
                        type: 'single',
                        field: 'isVoided',
                        component: UISwitch
                    }
                ]
            },
            {
                inlineLabel: true,
                label: 'Void Reason',
                rows: [
                    {
                        type: 'single',
                        field: 'voidReason',
                        component: UIInput
                    }
                ],
                condition: form => form.isVoided === true
            }
        ],
        dafInfo: [
            {
                inlineLabel: true,
                label: 'DAF Name',
                rows: [
                    {
                        type: 'single',
                        field: 'DAFName',
                        component: UIInput
                    }
                ]
            }
        ],
        iraInfo: [
            {
                inlineLabel: true,
                label: 'IRA Name',
                rows: [
                    {
                        type: 'single',
                        field: 'IRAName',
                        component: UIInput
                    }
                ]
            }
        ],
        matchingGiftInfo: [
            {
                inlineLabel: true,
                label: 'MatchingGift Name',
                rows: [
                    {
                        type: 'single',
                        field: 'MatchingGiftName',
                        component: UIInput
                    }
                ]
            }
        ],
        receipt: [
            {
                inlineLabel: true,
                label: 'Send me a receipt',
                class: 'smaller',
                rows: [
                    {
                        type: 'single',
                        field: 'sendReceipt',
                        component: UICheckBox
                    }
                ]
            },
            {
                inlineLabel: true,
                label: 'Send via',
                class: 'smaller',
                rows: [
                    {
                        type: 'single',
                        field: 'sendReceiptVia',
                        component: enumSelector(SendVia)
                    }
                ],
                condition: form => form.sendReceipt
            }
        ],
        details: [
            {
                inlineLabel: true,
                label: 'Notes',
                rows: [
                    {
                        type: 'single',
                        field: 'notes',
                        component: UIInput
                    }
                ]
            },
            {
                inlineLabel: true,
                label: 'Channel',
                class: 'smaller',
                rows: [
                    {
                        type: 'single',
                        field: 'channel',
                        component: enumSelector(PaymentChannel)
                    }
                ]
            },
            // checks
            {
                inlineLabel: true,
                label: 'Check Number',
                rows: [
                    {
                        type: 'single',
                        field: 'checkNum',
                        component: UIInput
                    }
                ],
                condition: form => form.type === PaymentType.Check
            },
            {
                inlineLabel: true,
                label: 'Front Scan',
                rows: [
                    {
                        type: 'single',
                        field: 'frontScan',
                        component: 'checkScan'
                    }
                ],
                condition: form => form.type === PaymentType.Check
            },
            {
                inlineLabel: true,
                label: 'Back Scan',
                rows: [
                    {
                        type: 'single',
                        field: 'backScan',
                        component: 'checkScan'
                    }
                ],
                condition: form => form.type === PaymentType.Check
            },
            {
                inlineLabel: true,
                label: 'MICR',
                condition: form => form.type === PaymentType.Check && this.paymentTypeChanged(form),
                rows: [
                    {
                        type: 'single',
                        field: 'MICR',
                        component: UIInput
                    }
                ]
            },
            {
                inlineLabel: true,
                label: 'Deposit Date',
                class: 'smaller',
                rows: [
                    {
                        type: 'single',
                        field: 'depositDate',
                        component: UIDatePicker
                    }
                ],
                condition: form => form.type === PaymentType.Check && this.paymentTypeChanged(form)
            },
            // credit card
            {
                inlineLabel: true,
                label: 'Transaction ID',
                rows: [
                    {
                        type: 'single',
                        field: 'transactionID',
                        component: UIInput
                    }
                ],
                condition: form => [
                    PaymentType.CreditCard,
                    PaymentType.ACH,
                    PaymentType.ApplePay,
                    PaymentType.Bit,
                    PaymentType.GooglePay,
                    PaymentType.Paypal,
                    PaymentType.Venmo
                ].includes(form.type)
            },
            // Charge Card
            {
                inlineLabel: true,
                label: 'Transaction ID',
                rows: [
                    {
                        type: 'single',
                        field: 'transactionID',
                        component: [UIInput, () => ({ props: { disabled: true } })]
                    }
                ],
                condition: form => form.type === PaymentType.ChargeCard
            },
            // Stock
            {
                inlineLabel: true,
                label: 'Date of sold',
                rows: [
                    {
                        type: 'single',
                        field: 'dateOfSold',
                        component: UIDatePicker
                    }
                ],
                condition: form => form.type === PaymentType.Stock
            },
            {
                inlineLabel: true,
                label: 'Stock Symbol',
                rows: [
                    {
                        type: 'single',
                        field: 'stockSymbol',
                        component: UIInput
                    }
                ],
                condition: form => form.type === PaymentType.Stock
            },
            {
                inlineLabel: true,
                label: 'How It Sold For',
                rows: [
                    {
                        type: 'single',
                        field: 'howItSold',
                        component: UIInput
                    }
                ],
                condition: form => form.type === PaymentType.Stock
            },
            {
                inlineLabel: true,
                label: 'High/Low',
                class: 'smaller',
                rows: [
                    {
                        type: 'single',
                        field: 'dayHigh',
                        component: UIInput
                    },
                    {
                        type: 'single',
                        field: 'dayLow',
                        component: UIInput
                    }
                ],
                condition: form => form.type === PaymentType.Stock
            },
            {
                inlineLabel: true,
                label: '# of Shares',
                class: 'smaller',
                rows: [
                    {
                        type: 'single',
                        field: 'numberOfShares',
                        component: UIInput
                    }
                ],
                condition: form => form.type === PaymentType.Stock
            },
            // Crypto
            {
                inlineLabel: true,
                label: 'Bank',
                class: 'smaller',
                rows: [
                    {
                        type: 'single',
                        field: 'bank',
                        component: enumSelector(WireBank)
                    }
                ],
                condition: form => form.type === PaymentType.Crypto
            },
            {
                inlineLabel: true,
                label: 'Crypto Type',
                class: 'smaller',
                rows: [
                    {
                        type: 'single',
                        field: 'cryptoType',
                        component: UIInput
                    }
                ],
                condition: form => form.type === PaymentType.Crypto
            },
            {
                inlineLabel: true,
                label: 'Exchange Rate',
                class: 'smaller',
                rows: [
                    {
                        type: 'single',
                        field: 'exchangeRate',
                        component: NumberInput
                    }
                ],
                condition: form => form.type === PaymentType.Crypto
            }
        ]
    };
    hideVoidModal() {
        this.paymentForm = null;
        this.isVoidModalVisible = false;
    }
    paymentTypeChanged(form) {
        if (!this.payment)
            return false;
        return this.payment.type !== form.type;
    }
    paymentBatchChanged(form) {
        if (!this.payment)
            return false;
        return this.payment.batch?.id !== form.batch.id;
    }
    onVoidModalSubmit(voidOptions) {
        if (!this.paymentForm)
            return;
        this.submit({ values: this.paymentForm }, voidOptions);
    }
    get initial() {
        const data = {
            batch: this.payment && this.payment.batch,
            batchDate: this.payment?.batchDate || null,
            date: this.payment && this.payment.date,
            DAFName: this.payment && this.payment.DAFName,
            IRAName: this.payment && this.payment.IRAName,
            MatchingGiftName: this.payment && this.payment.MatchingGiftName,
            category: this.payment && this.payment.category,
            sendReceipt: this.payment && !!this.payment.sendReceiptVia,
            sendReceiptVia: this.payment && this.payment.sendReceiptVia,
            type: this.payment && this.payment.type,
            donorName: this.payment && this.payment.donorName,
            transactionID: this.payment && this.payment.transactionID,
            frontScan: this.payment?.check?.frontScan ? { file: this.payment?.check?.frontScan, status: '' } : null,
            backScan: this.payment?.check?.backScan ? { file: this.payment?.check?.backScan, status: '' } : null,
            checkNum: this.payment && this.payment.check && this.payment.check.checkNum,
            receivedDate: (this.payment && this.payment.check && this.payment.check.receivedDate) || null,
            MICR: null,
            depositDate: null,
            description: null,
            notes: (this.payment && this.payment.notes) || null,
            isVoided: this.payment && this.payment.status === PaymentStatus.Voided ? true : false,
            voidReason: (this.payment && this.payment.voidReason) || null,
            applications: (this.payment && this.payment.applications) || [],
            dateOfSold: this.payment?.stock?.dateOfSold || null,
            stockSymbol: this.payment?.stock?.stockSymbol || null,
            howItSold: this.payment?.stock?.howItSold || null,
            dayHigh: this.payment?.stock?.dayHigh || null,
            dayLow: this.payment?.stock?.dayLow || null,
            numberOfShares: this.payment?.stock?.numberOfShares || null,
            ...(this.payment || {}),
            amount: this.payment
                ? {
                    amount: this.payment.originalAmount || this.payment.amount,
                    currency: this.payment.currency || null
                }
                : null,
            convertedAmount: this.payment?.amount || null,
            wireBank: this.payment?.wireBank || null,
            cryptoType: this.payment?.crypto?.cryptoType || null,
            exchangeRate: this.payment?.crypto?.exchangeRate || null,
            bank: this.payment?.crypto?.bank || null,
            channel: this.payment?.channel || null
        };
        return data;
    }
    pledgesCopy = (this.payment &&
        this.payment.applications.map(x => ({
            ...x,
            actions: {
                setAmount: this.setAmount
            }
        }))) ||
        [];
    setAmount(pledge, amount) {
        const pledgesCopy = [...this.pledgesCopy];
        const p = pledgesCopy.find(x => x.pledgeId === pledge.pledgeId);
        p.amount = amount;
        this.pledgesCopy = pledgesCopy;
    }
    validate(values) {
        const errors = {};
        if (!values.date) {
            errors['date'] = 'Date is required';
        }
        if (values.amount === null) {
            errors['amount'] = 'Amount is required';
        }
        else if (values.amount && values.amount.amount <= 0) {
            errors['amount'] = 'Enter an amount greater than 0';
        }
        if (!values.batch) {
            errors['batch'] = 'Please select a batch';
        }
        if (values.sendReceipt && !values.sendReceiptVia) {
            errors['sendReceiptVia'] = 'Receipt type should be checked';
        }
        if (values.isVoided && !values.voidReason) {
            errors['voidReason'] = 'Void reason is required';
        }
        if (addWithRounding(values.applications.map(x => x.originalAmount)) !== values.amount.amount) {
            errors['amount'] = `The amount of pledges must equal the payment amount (${addWithRounding(values.applications.map(x => x.originalAmount))})`;
        }
        return errors;
    }
    async submit(args, voidForm) {
        // void form is passed, when confirmation modal with void options is filled up and closed
        if ('errors' in args)
            return;
        if (this.payment &&
            this.payment.status !== PaymentStatus.Voided &&
            args.values.isVoided &&
            args.values.voidReason) {
            if (!voidForm) {
                this.isVoidModalVisible = true;
                this.paymentForm = args.values;
                return;
            }
            await this.voidPayment(this.paymentId, {
                markPledgesUncollectible: voidForm.uncollectible,
                uncollectibleReason: voidForm.uncollectibleReason,
                reason: args.values.voidReason
            });
            this.isVoidModalVisible = false;
            this.paymentForm = null;
            return;
        }
        if (this.payment && this.payment.status === PaymentStatus.Voided && !args.values.isVoided) {
            await this.unvoidPayment(this.paymentId);
        }
        const checkUpdateData = args.values.type === PaymentType.Check
            ? {
                checkNum: args.values.checkNum
            }
            : {};
        if (this.payment && this.payment.type !== args.values.type) {
            const updatePaymentTypeInput = {
                id: this.payment.id,
                type: args.values.type
            };
            if (args.values.type === PaymentType.Check) {
                updatePaymentTypeInput.check = {
                    MICR: args.values.MICR,
                    depositDate: args.values.depositDate
                };
            }
            if (args.values.type === PaymentType.CreditCard) {
                updatePaymentTypeInput.creditCard = {
                    transactionID: args.values.transactionID
                };
            }
            if (args.values.type === PaymentType.InKind) {
                updatePaymentTypeInput.inkind = {
                    description: args.values.description
                };
            }
            if (args.values.type === PaymentType.Stock) {
                updatePaymentTypeInput.stock = {
                    dateOfSold: args.values.dateOfSold,
                    stockSymbol: args.values.stockSymbol,
                    howItSold: args.values.howItSold,
                    dayHigh: args.values.dayHigh,
                    dayLow: args.values.dayLow,
                    numberOfShares: args.values.numberOfShares
                };
            }
            if (args.values.type === PaymentType.Crypto) {
                updatePaymentTypeInput.crypto = {
                    cryptoType: args.values.cryptoType,
                    exchangeRate: args.values.exchangeRate,
                    bank: args.values.bank
                };
            }
            await this.updatePaymentType(updatePaymentTypeInput);
        }
        await this.updatePayment(args.values);
        notify('Payment was saved successfuly');
        setTimeout(() => {
            this.$emit('updated');
        }, 1000);
    }
};
__decorate([
    Inject('router'),
    __metadata("design:type", Object)
], EditPayment.prototype, "router", void 0);
__decorate([
    Prop({ type: Function, required: true }),
    __metadata("design:type", Object)
], EditPayment.prototype, "updatePayment", void 0);
__decorate([
    Prop({ type: Function, required: true }),
    __metadata("design:type", Object)
], EditPayment.prototype, "updatePaymentType", void 0);
__decorate([
    Prop({ type: Function, required: true }),
    __metadata("design:type", Object)
], EditPayment.prototype, "voidPayment", void 0);
__decorate([
    Prop({ type: Function, required: true }),
    __metadata("design:type", Object)
], EditPayment.prototype, "unvoidPayment", void 0);
__decorate([
    Prop({ type: Function, required: true }),
    __metadata("design:type", Object)
], EditPayment.prototype, "deletePayment", void 0);
__decorate([
    Prop({ type: Function, required: true }),
    __metadata("design:type", Object)
], EditPayment.prototype, "movePayment", void 0);
__decorate([
    Prop({ type: Boolean, required: true }),
    __metadata("design:type", Object)
], EditPayment.prototype, "processing", void 0);
__decorate([
    Prop({ type: Object }),
    __metadata("design:type", Object)
], EditPayment.prototype, "payment", void 0);
__decorate([
    Prop({ type: String, required: true }),
    __metadata("design:type", Object)
], EditPayment.prototype, "paymentId", void 0);
__decorate([
    Prop({ type: String, required: true }),
    __metadata("design:type", Object)
], EditPayment.prototype, "pledgeCurrency", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: true }),
    __metadata("design:type", Boolean)
], EditPayment.prototype, "showAudit", void 0);
__decorate([
    Prop({ type: String }),
    __metadata("design:type", String)
], EditPayment.prototype, "access_token", void 0);
EditPayment = __decorate([
    Component({
        components: {
            SmallPaymentAuditLog,
            layoutFormRow,
            S3Uploader,
            Box,
            UIButton,
            FormSection,
            Card,
            UILabel,
            AmountWithCurrency,
            FormBuilder,
            UIInput,
            UISelect,
            UICheckBox,
            GetSignedUrl,
            VoidModal,
            Modal,
            Icon,
            ProtectedBlock,
            layoutText,
            DonorLookup,
            Popover,
            ...TableGroup,
            ReceiptPreview,
            NumberInput
        }
    })
], EditPayment);
export default EditPayment;
